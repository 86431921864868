<script>
import LiveSessionForm from "@/views/live-session/liveSessionForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    liveSessionService: "liveSessionService",
    toastService: "toastService",
    visitorLevelService: "visitorLevelService",
  },
})
export default class LiveSession_new extends mixins(LiveSessionForm) {
  formId = "new-liveSession-form";

  get queryParameters() {
    return this.$route.params;
  }

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      let cleanModel = Utility.clone(this.model);
      cleanModel.duration = parseInt(cleanModel.duration);
      if (this.model?.invitation?.enableInvitationLink) {
        cleanModel.invitation = { code: cleanModel.invitation.code };
      } else {
        delete cleanModel.invitation;
      }
      if(cleanModel.openingOption != "Popup") {
        cleanModel.localizations.forEach(loc => {
          loc.sidecardTitle = null;
          loc.sidecardLinkUri = null;
        });
      }
      await this.liveSessionService.create(cleanModel);
      this.toastService.success(this.translations.success.liveSession_create);
      this.$router.push(
        `/live-session/list/${this.queryParameters.idParent}/${this.queryParameters.parentName}`
      );
    } catch (error) {
      this.errors = this.formatApiErrors(error);
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.liveSessions_new;
  }

  async afterLoad() {
    this.setVisitorLevels(
      await this.visitorLevelService.enabledLevels(this.model.id)
    );
    this.model = {
      idParent: this.queryParameters.idParent,
      type: "",
      code: "",
      parentType: this.queryParameters.parentName,
      platform: "",
      openingOption: "",
      showInAgenda: false,
      isAvailable: true,
      startDate: "",
      duration: 0,
      localizations: [],
      speakers: [],
      invitation: {},
      visitorLevels: this.defaultVisitorLevelsValue(),
    };

    this.isReady = true;
  }
}
</script>
