import Vue from "vue";
import Component from "vue-class-component";

@Component()
export default class WithVisitorLevelPermissions extends Vue {
  currentVisitorLevels = [];

  get currentVisitorLevelsOptions() {
    return this.currentVisitorLevels.map(cl => {
        return {
          value: cl.id,
          label: cl.code
        };
      });
  }

  defaultVisitorLevelsValue() {
    return this.currentVisitorLevels
      .filter(vl => vl.defaultValue)
      .map(vl => vl.id);
  }

  currentVisitorLevelsValue() {
    return this.currentVisitorLevels
      .filter(vl => vl.enabled)
      .map(vl => vl.id);
  }

  setVisitorLevels(levels) {
    this.currentVisitorLevels = levels;
  }
}