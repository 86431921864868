import EntityForm from "@/mixins/EntityForm";
import WithVisitorLevelPermissions from "@/mixins/WithVisitorLevelPermissions";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility.js";

@Component({
  inject: {
    uiService: "uiService",
    userService: "userService",
    liveSessionService: "liveSessionService",
    languageService: "languageService",
    eventService: "eventService",
  },
  watch: {
    selectedPlatform: async function (val) {
      if (val.toLowerCase() == "webex" || val.toLowerCase() == "teams") {
        this.openingOptions = this.openingOptions.filter(o => o.toLowerCase() == "newwindow");
      } else {
        this.openingOptions = await this.liveSessionService.openingOptions();
      }
    },
  },
})
export default class LiveSessionForm extends mixins(EntityForm, WithVisitorLevelPermissions) {
  model = {};
  platformLanguages = [];
  types = [];
  platforms = [];
  speakers = [];
  openingOptions = [];
  generatingPassCodes = false;
  ui = {};
  isEnableAgendaCardPopup = false;
  isShowInAgenda = false;

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get selectedPlatform() {
    return this.model?.platform ?? "";
  }

  get openingOption() {
    return this.model?.openingOption ?? "";
  }

  get labelForPublicLink() {
    const selectedPlat = this.selectedPlatform.toLowerCase();
    if (selectedPlat == "youtube") {
      return this.translations.labels.liveSession_form_idYouTubeVideo;
    }
    return this.translations.labels.liveSession_form_publicLinkUri;
  }

  get typesOptions() {
    return this.types.map((t) => {
      return {
        value: t,
        label: t,
      };
    });
  }

  get platformsOptions() {
    return this.platforms.map((t) => {
      return {
        value: t,
        label: t,
      };
    });
  }

  get openingOptionsOptions() {
    return this.openingOptions.map((t) => {
      return {
        value: t,
        label: t,
      };
    });
  }

  get selectedLanguages() {
    //const modelLanguages = this.model?.languageIds ?? [];
    const modelLanguages = this.platformLanguages;
    return this.platformLanguages.filter((l) => {
      return modelLanguages.includes(l.id);
    });
  }

  get selectedLanguagesOptions() {
    const selectedLanguages = this.selectedLanguages.map((l) => {
      return {
        value: l.id,
        label: l.description,
      };
    });
    return selectedLanguages;
  }

  get speakersOption() {
    return this.speakers.map((t) => {
      return {
        value: t.id,
        label: t.lastName,
      };
    });
  }

  get elements() {
    const childElements = (this?.ui?.childElements ?? []);
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "code",
            label: this.translations.labels.liveSession_form_code,
            type: "text",
            rules: "max:15",
            required: true
          },
          {
            id: "type",
            label: this.translations.labels.liveSession_form_type,
            type: "select",
            required: true,
            items: this.typesOptions,
          },
          {
            id: "platform",
            label: this.translations.labels.liveSession_form_platform,
            type: "select",
            required: true,
            items: this.platformsOptions,
          },
          {
            id: "openingOption",
            label: this.translations.labels.liveSession_form_openingOption,
            type: "select",
            required: true,
            items: this.openingOptionsOptions,
          },
          ...Utility.hideIf(
            {
              id: "showInAgenda",
              label: this.translations.labels.liveSession_form_showInAgenda,
              type: "checkbox",
              required: false,
              onChange: (val) => {
                this.isShowInAgenda = val;
              }
            },
            childElements.includes("showInAgenda")
          ),
          ...Utility.hideIf(
            {
              id: "enableAgendaCardPopup",
              label: this.translations.labels.liveSession_form_enableAgendaCardPopup,
              type: "switch",
              required: false,
              disabled: !this.isShowInAgenda,
              onChange: (val) => {
                this.isEnableAgendaCardPopup = val;
              }
            }),
          ...Utility.hideIf(
            {
              id: "fullSizeOpened",
              label: this.translations.labels.liveSession_form_fullSizeOpened,
              type: "checkbox",
              required: false,
            },
            childElements.includes("fullSizeOpened")
          ),
          ...Utility.hideIf(
            {
              id: "isAvailable",
              label: this.translations.labels.liveSession_form_isAvailable,
              type: "checkbox",
              required: false,
            },
            childElements.includes("isAvailable")
          ),
          ...Utility.hideIf(
            {
              id: "startDate",
              label: this.translations.labels.liveSession_form_startDate,
              type: "datetime",
              required: true,
            },
            childElements.includes("startDate")
          ),
          ...Utility.hideIf(
            {
              id: "duration",
              label: this.translations.labels.liveSession_form_duration,
              type: "number",
              required: true,
            },
            childElements.includes("duration")
          ),
          ...Utility.hideIf(
            {
              id: "speakers",
              label: this.translations.labels.liveSession_form_speakers,
              type: "autocomplete",
              multiple: true,
              items: this.speakersOption,
            },
            childElements.includes("speakers")
          ),
          ...Utility.hideIf(
            {
              id: "enablePassCodes",
              label: this.translations.labels.liveSession_form_enablePassCodes,
              type: "checkbox",
              required: false,
            },
            childElements.includes("enablePassCodes")
          ),
          ...Utility.hideIf(
            {
              id: "enableUserChoice",
              label: this.translations.labels.liveSession_form_enableUserChoice,
              type: "checkbox",
              required: false,
              hide: () => !["teams", "webex"].includes(this.model.platform.toLowerCase())
            },
            childElements.includes("enableUserChoice")
          ),
          ...Utility.hideIf(
            {
              id: "localizations",
              type: "localizations",
              header: this.translations.labels.liveSession_form_localizations,
              languages: this.platformLanguages,
              resetColumns: true,
              elements: [
                {
                  id: "speakerLinkUri",
                  label: this.translations.labels.liveSession_form_speakerLinkUri,
                  type: "text",
                  required: true,
                },
                {
                  id: "publicLinkUri",
                  label: this.labelForPublicLink,
                  type: "text",
                  required: true,
                },
                {
                  id: "speakerPresentationLinkUri",
                  label: this.translations.labels
                    .liveSession_form_speakerPresentationLinkUri,
                  type: "text",
                },
                {
                  id: "publicPresentationLinkUri",
                  label: this.translations.labels
                    .liveSession_form_publicPresentationLinkUri,
                  type: "text",
                },
                {
                  id: "title",
                  label: this.translations.labels.liveSession_form_title,
                  type: "text",
                  rules: "max:200",
                  required: true
                },
                {
                  id: "description",
                  label: this.translations.labels.liveSession_form_description,
                  type: "textarea",
                  rules: "max:50",
                  required: true
                },
                {
                  id: "speakerDescription",
                  label: this.translations.labels.liveSession_form_speakerDescription,
                  type: "textarea",
                },
                {
                  id: "sidecardLinkUri",
                  label: this.translations.labels.liveSession_form_sidecardLinkUri,
                  hint: this.translations.labels.tokenizeData,
                  type: "text",
                  hide: () => this.model.openingOption != "Popup",
                },
                {
                  id: "sidecardTitle",
                  label: this.translations.labels.liveSession_form_sidecardTitle,
                  type: "text",
                  hide: () => this.model.openingOption != "Popup",
                },
                {
                  id: "imageUri",
                  label: this.translations.labels.liveSession_form_imageUri,
                  type: "media",
                  mediaType: "image",
                  required: false
                },
                {
                  id: "cardPopupButtonText",
                  label: this.translations.labels.liveSession_form_cardPopupButtonText,
                  type: "text",
                  rules: "max:50",
                  required: false,
                  disabled: !this.isEnableAgendaCardPopup || !this.isShowInAgenda
                },
                {
                  id: "cardPopupContent",
                  label: this.translations.labels.liveSession_form_content,
                  type: "wysiwyg",
                  required: false,
                  resetColumns: 1,
                  disabled: !this.isEnableAgendaCardPopup || !this.isShowInAgenda
                }
              ],
            },
            childElements.includes("localizations")
          ),
        ]
      },
      ...Utility.hideIf(
        {
          id: "invitation",
          type: "invitation",
          resetColumns: true,
          legend: this.translations.labels.invitation_label
        },
        childElements.includes("invitation")
      ),
      ...Utility.hideIf(
        {
          id: "visitorLevels",
          type: "visitor-levels-permissions",
          resetColumns: true,
          transcludeModel: true,
          legend: this.translations.labels.visitor_level,
          label: this.translations.labels.visitor_level,
          levels: this.currentVisitorLevelsOptions
        },
        childElements.includes("visitorLevels")
      ),
    ];
  }

  get helpLink() {
    return this.translations.pageHelp.liveSession;
  }

  passCodesFieldset(complete) {
    const fieldsetElements = [];
    if (complete) {
      fieldsetElements.push(
        {
          id: "generatePassCodes",
          type: "number",
          min: 0,
          max: 1000,
          label: this.translations.labels.passCodes_generate,
          placeholder: this.translations.labels.passCodes_generate_placeholder,
          hint: this.translations.labels.passCodes_generate_hint,
          rightIcon: "mdi-plus-box-multiple-outline",
          onClickRightIcon: async () => {
            const howMany = this.model.generatePassCodes ?? 0;
            if (howMany <= 0) {
              alert(this.translations.errors.atLeastOnePassCodes);
              return;
            } else if (howMany > 1000) {
              alert(this.translations.errors.atMostOnePassCodes);
              return;
            }
            this.generatingPassCodes = true;
            try {
              await this.passCodeService.generate("LiveSession", this.$route.params.id, howMany);
              this.toastService.success(this.translations.success.passCode_generated);
            } catch (error) {
              this.toastService.error(this.translations.errors.somethingWrong);
            }
            this.generatingPassCodes = false;
          },
          disabled: this.generatingPassCodes
        }
      );
    }
    fieldsetElements.push(
      {
        id: "downloadPassCodes",
        type: "button",
        label: this.translations.labels.passCodes_download,
        rightIcon: "mdi-download",
        handler: async () => {
          try {
            const result = await this.passCodeService.export("LiveSession", this.$route.params.id);

            const columns = [
              "code",
              "createdAt",
              "redeemed",
              "redeemedAt",
              "redeemedByUser",
            ];

            Utility.exportResults(this.translations.exportLabels, "passCodes", columns, result);
          } catch (error) {
            this.toastService.error(this.translations.errors.somethingWrong);
          }
        },
        disabled: this.generatingPassCodes
      }
    );
    return {
      id: "passCodes",
      type: "fieldset",
      collapsible: true,
      open: false,
      resetColumns: true,
      legend: this.translations.labels.passCodes_label,
      elements: fieldsetElements
    };
  }

  // eslint-disable-next-line no-empty-function
  afterLoad() { }

  async created() {
    this.platformLanguages = await this.eventService.relatedLanguages(
      this.$route.params.idParent
    );

    this.ui = await this.uiService.uiFormHide("live-session");
    this.types = await this.liveSessionService.types();
    this.platforms = await this.liveSessionService.platforms();
    this.openingOptions = await this.liveSessionService.openingOptions();
    this.speakers = await this.userService.speakers();

    this.afterLoad();
  }
}
